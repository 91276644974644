<template>
  <AccordBlock>
    <template slot="header">
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_ShippingCompanies',])"></div>
        {{$t('siteSetting_ShippingCompanies.localization_value.value')}}
      </div>
    </template>

    <template slot="body">
      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_DHLBased',])"></div>
        {{$t('siteSetting_DHLBased.localization_value.value')}}

      </div>
      <div class="custom-row pt-3">
        <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_Ukraine',])"></div>
          <InputSum
                  :label="$t('siteSetting_Ukraine.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(shippingCompanies.data.ukrainian)"
                  @change="(val) => {shippingCompanies.data.ukrainian = val}"
          />
          <!--          v-model="shippingCompanies.data.ukrainian"-->
        </div>
        <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_America',])"></div>
          <InputSum
                  :label="$t('siteSetting_America.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(shippingCompanies.data.usa)"
                  @change="(val) => {shippingCompanies.data.usa = val}"
          />
        </div>
<!--        <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['siteSetting_Belarus',])"></div>-->
<!--          <InputSum-->
<!--                  :label="$t('siteSetting_Belarus.localization_value.value')"-->
<!--                  :icoType="'dollar'"-->
<!--                  :value="setPriceValue(shippingCompanies.data.belarus)"-->
<!--                  @change="(val) => {shippingCompanies.data.belarus = val}"-->
<!--          />-->
<!--        </div>-->
      </div>

      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_TNTBased',])"></div>
        {{$t('siteSetting_TNTBased.localization_value.value')}}
      </div>

      <div class="custom-row pt-2">
        <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_TNTPercentShippingPrice',])"></div>
          <InputSum
                  class="label-wrap"
                  :label="$t('siteSetting_TNTPercentShippingPrice.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(shippingCompanies.data.TNTPercentDefault)"
                  @change="(val) => {shippingCompanies.data.TNTPercentDefault = val}"
          />
        </div>
        <!--<div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50">-->
        <!--<InputSum-->
        <!--class="label-wrap"-->
        <!--:label="'% on shipping price for VIPs'"-->
        <!--:icoType="'dollar'"-->
        <!--v-model="input07"-->
        <!--/>-->
        <!--</div>-->
      </div>

      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_FedExBased',])"></div>
        {{$t('siteSetting_FedExBased.localization_value.value')}}
      </div>

      <div class="custom-row pt-2">
        <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_FedexPercentShippingPriceUa',])"></div>
          <InputSum
                  class="label-wrap"
                  :label="$t('siteSetting_FedexPercentShippingPriceUa.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(shippingCompanies.data.FedexPercentDefault)"
                  @change="(val) => {shippingCompanies.data.FedexPercentDefault = val}"
          />

          <!--          :label="'% on shipping price for users'"-->


          <!--          <InputSum-->
          <!--              class="label-wrap"-->
          <!--              :label="'% on shipping price for users'"-->
          <!--              :icoType="'dollar'"-->
          <!--              v-model="input08"-->
          <!--          />-->
        </div>
<!--        <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['siteSetting_FedexPercentShippingPriceRu',])"></div>-->
<!--          <InputSum-->
<!--                  class="label-wrap"-->
<!--                  :label="$t('siteSetting_FedexPercentShippingPriceRu.localization_value.value')"-->
<!--                  :icoType="'dollar'"-->
<!--                  :value="setPriceValue(shippingCompanies.data.FedexRussiaPercentDefault)"-->
<!--                  @change="(val) => {shippingCompanies.data.FedexRussiaPercentDefault = val}"-->
<!--          />-->

<!--          &lt;!&ndash;          :label="'% on shipping price for VIPs'"&ndash;&gt;-->

<!--          &lt;!&ndash;          <InputSum&ndash;&gt;-->
<!--          &lt;!&ndash;              class="label-wrap"&ndash;&gt;-->
<!--          &lt;!&ndash;              :label="'% on shipping price for VIPs'"&ndash;&gt;-->
<!--          &lt;!&ndash;              :icoType="'dollar'"&ndash;&gt;-->
<!--          &lt;!&ndash;              v-model="input09"&ndash;&gt;-->
<!--          &lt;!&ndash;          />&ndash;&gt;-->
<!--        </div>-->

        <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_FedexPercentShippingPriceBaltic',])"></div>
          <InputSum
              class="label-wrap"
              :label="$t('siteSetting_FedexPercentShippingPriceBaltic.localization_value.value')"
              :icoType="'dollar'"
              :value="setPriceValue(shippingCompanies.data.FedexBalticPercentDefault)"
              @change="(val) => {shippingCompanies.data.FedexBalticPercentDefault = val}"
          />
        </div>

        <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_FedexPercentShippingPricePl',])"></div>
          <InputSum
              class="label-wrap"
              :label="$t('siteSetting_FedexPercentShippingPricePl.localization_value.value')"
              :icoType="'dollar'"
              :value="setPriceValue(shippingCompanies.data.FedexPolandPercentDefault)"
              @change="(val) => {shippingCompanies.data.FedexPolandPercentDefault = val}"
          />
        </div>

        <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_FedexPercentShippingPriceGB',])"></div>
          <InputSum
              class="label-wrap"
              :label="$t('siteSetting_FedexPercentShippingPriceGB.localization_value.value')"
              :icoType="'dollar'"
              :value="setPriceValue(shippingCompanies.data.FedexGBPercentDefault)"
              @change="(val) => {shippingCompanies.data.FedexGBPercentDefault = val}"
          />
        </div>
      </div>

<!--      <div class="section-label"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate(['siteSetting_CDEKBased',])"></div>-->
<!--        {{$t('siteSetting_CDEKBased.localization_value.value')}}-->
<!--      </div>-->

<!--      <div class="custom-row pt-2">-->
<!--        <div class="custom-col custom-col&#45;&#45;16 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--          <div class="admin-edit" @click="editTranslate(['siteSetting_CDEKPercentShippingPrice',])"></div>-->
<!--          <InputSum-->
<!--                  class="label-wrap"-->
<!--                  :label="$t('siteSetting_CDEKPercentShippingPrice.localization_value.value')"-->
<!--                  :icoType="'dollar'"-->
<!--                  :value="setPriceValue(shippingCompanies.data.CDEKShippingPrice)"-->
<!--                  @change="(val) => {shippingCompanies.data.CDEKShippingPrice = val}"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->

      <div class="section-label"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        Nova poshta global
      </div>

      <div class="custom-row pt-2">
        <div class="custom-col custom-col--16 custom-col--md-33 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['siteSetting_CDEKPercentShippingPrice',])"></div>
          <InputSum
                  class="label-wrap"
                  :label="$t('siteSetting_CDEKPercentShippingPrice.localization_value.value')"
                  :icoType="'dollar'"
                  :value="setPriceValue(shippingCompanies.data.NovaPoshtaGlobalPercentDefault)"
                  @change="(val) => {shippingCompanies.data.NovaPoshtaGlobalPercentDefault = val}"
          />
        </div>
      </div>
    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import InputSum from "../../../../../../../UI/inputs/InputSum/InputSum";

  export default {
    name: "ShippingCompanies",
    components: {
      AccordBlock,
      InputSum,
    },

    mixins: [mixinDetictingMobile],

    props: {
      shippingCompanies: Object,
    },

    data() {
      return {
        input01: '',
        input02: '',
        input03: '',
        input04: '',
        input05: '',
        input06: '',
        input07: '',
        input08: '',
        input09: '',
        input10: '',
        input11: '',
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
