<template>
  <AccordBlock>
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['siteSetting_Engraving'])"></div>
        {{$t('siteSetting_Engraving.localization_value.value')}}
      </div>
    </template>

    <template slot="body">


      <div class="custom-row mt-3">
        <div class="custom-col">

          <div class="custom-row">

            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_oneHourEngravingCost'])"></div>
              <InputSum
                  :label="$t('siteSetting_oneHourEngravingCost.localization_value.value')"
                  :placeholder="'0'"
                  :icoType="'dollar'"
                  v-model="engraving.data.oneHourEngravingCost"
              />
            </div>
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['siteSetting_engravingTariffPerArea'])"></div>-->
<!--              <DefaultInput-->
<!--                  :label="$t('siteSetting_engravingTariffPerArea.localization_value.value')"-->
<!--                  :placeholder="'0'"-->
<!--                  v-model="engraving.data.engravingTariffPerArea"-->
<!--              />-->
<!--            </div>-->
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['siteSetting_engravingTariff'])"></div>-->
<!--              <DefaultInput-->
<!--                  :label="$t('siteSetting_engravingTariff.localization_value.value')"-->
<!--                  :placeholder="'0'"-->
<!--                  v-model="engraving.data.engravingTariff"-->
<!--              />-->
<!--            </div>-->
            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_engravingTariffDifferent'])"></div>
              <DefaultInput
                  :label="$t('siteSetting_engravingTariffDifferent.localization_value.value')"
                  :placeholder="'0'"
                  v-model="engraving.data.engravingTariffDifferent"
              />
            </div>
            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_engravingAdjustingTheLayout'])"></div>
              <DefaultInput
                  :label="$t('siteSetting_engravingAdjustingTheLayout.localization_value.value')"
                  :placeholder="'0'"
                  v-model="engraving.data.engravingAdjustingTheLayout"
              />
            </div>
<!--            <div class="custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50"-->
<!--                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--              <div class="admin-edit" @click="editTranslate(['siteSetting_engravingTariffPerOrder'])"></div>-->
<!--              <DefaultInput-->
<!--                  :label="$t('siteSetting_engravingTariffPerOrder.localization_value.value')"-->
<!--                  :placeholder="'0'"-->
<!--                  v-model="engraving.data.engravingTariffPerOrder"-->
<!--              />-->
<!--            </div>-->
            <div class="custom-col custom-col--33 custom-col--md-33 custom-col--sm-50 mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['siteSetting_engravingDiscount'])"></div>
              <DefaultInput
                  :label="$t('siteSetting_engravingDiscount.localization_value.value')"
                  :placeholder="'0'"
                  v-model="engraving.data.engravingDiscount"
              />
            </div>

          </div>

        </div>
      </div>


    </template>
  </AccordBlock>
</template>

<script>
  import AccordBlock from "../../../../../../../coreComponents/AccordBlock/AccordBlock";
  import {mixinDetictingMobile} from "../../../../../../../../mixins/mobileFunctions";
  import InputSum from "../../../../../../../UI/inputs/InputSum/InputSum";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  // import DefaultSelect from "../../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";

  export default {
    name: "GeneralSystemPreferences",
    components: {
      DefaultInput,
      AccordBlock,
      InputSum,
      // DefaultSelect,
      // DefaultCheckbox,
    },

    mixins: [mixinDetictingMobile],

    props: {
      engraving: Object,
    },

    data() {
      return {
        options: [{}],

        checkox01: true,

        input01: '',
        input02: '',
        input03: '',
        input04: '',
        input05: '',
        input06: '',
        input07: '',
        input08: '',
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
